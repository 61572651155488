:local(.container){
    position:relative;
    height:100vh;
    --sidebar-width: 250px;
    --header-height: 50px;
    --footer-height:40px;
}
:local(.sidebar){
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently */

    position: absolute;
    left: 0;
    bottom:var(--footer-height);
    top:0;
    width:var(--sidebar-width);
    background-color: #fff;
    box-shadow: 2px 0px 8px rgba(0,0,0,.2);
    z-index:1;

    
    :local(.logo){
        height: 100px;
        padding: var(--spacing);
        img{
            height: 100%;
        }
    }
}

:local(.user){
    padding: var(--spacing);
    border:1px  var(--gray6);
    font-size: var(--small);
    border-style: solid none;
    :local(.logout){
        margin-top: var(--spacing_half);
    }
}


:local(.menu){
    margin-top: var(--spacing);
    :local(.options .option){
        padding:var(--spacing);
        border-bottom:1px solid var(--gray6);
        font-size: var(--normal);
        svg{
            display:none;
        }
        a{
            color: var(--gray3);
            text-decoration: none;
        }
        &.selected{
            font-weight: bold;
            svg{
                display:inline-block;
                margin-right: var(--spacing_half);
            }
            a{
                color: var(--gray2);
            }
        }
    }
}

:local(.main){
    z-index:0;
    position:absolute;
    left: var(--sidebar-width);
    right:0;
    top:0;
    bottom:0;
    
    overflow:auto;
}

:local(.footer){
    border-top:1px solid var(--gray7);
    position:absolute;
    bottom:0;
    left:0;
    z-index:2;
    right:0;
    height:var(--footer-height);
    padding:var(--spacing_half);
    background-color:#fff;

    :local(.version){
        font-size:var(--small);
    }
}


@media(max-width:640px){
    :local(.menu .options){
        overflow-x: auto;
        /* We don't want vertical scrolling */
        overflow-y: hidden;
        /* Make an auto-hiding scroller for the 3 people using a IE */
        -ms-overflow-style: -ms-autohiding-scrollbar;
        /* For WebKit implementations, provide inertia scrolling */
        -webkit-overflow-scrolling: touch;
        /* We don't want internal inline elements to wrap */
        white-space: nowrap;
        /* Remove the default scrollbar for WebKit implementations */
        &::-webkit-scrollbar {
            display: none;
        }

        :local(.option){
            font-size: var(--normal);
            padding:0px 15px;
        }
    }

}


@media (max-width: 600px){
    :local(.sidebar){
        position: absolute;
        left: 0;
        right: 0;
        top:0;
        bottom:auto;
        width:100%;
        height:var(--header-height);
        :local(.logo){
            display: none;
        }
        :local(.user){
            display: none;
        }
        :local(.menu){
            margin-top:0;
            margin-bottom:0;
            :local(.options .option){
                padding:var(--spacing_half);
                display: inline-block;
                border-bottom:5px solid transparent;
                &.selected{
                    border-bottom:5px solid var(--gray2);
                    font-weight: bold;
                    svg{
                        display:none;
                    }
                }
            }
        }  
    }
    :local(.main){
        position: absolute;
        left: 0;
        right: 0;
        top:var(--header-height);
        bottom:var(--footer-height);
        width:100%;
        height:auto;
    }
}