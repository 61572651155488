:local(.page){
    padding:var(--spacing);
    :local(.secAction){
        float: right;
    }
    :local(.breadcrumb){
        margin-top:var(--spacing);
        margin-bottom: -20px;
        font-size: var(--small);
        color:var(--gray2);
        :local(.el::after){
            content:" » "
        }
        :local(.el:last-child::after){
            content:""
        }
    }
    :local(.title){
        margin:var(--spacing) 0px;
        font-size: var(--big4x);
        color:var(--gray2);
    }
    :local(.subtitle){
        margin:var(--spacing) 0px;
        font-size: var(--small);
        color:var(--gray2);
    }
    :local(.title+.subtitle){
        margin-top:-20px;
    }
    :local(.sectionHeader){
        margin:var(--spacing) 0px;
        padding:0;
        font-size: var(--big);
        color:var(--gray2);
    
    }
    :local(.sectionHeaderInfo){
        margin:0px 0px var(--spacing);
        padding:0;
        margin-top:-20px;
        font-size: var(--normal);
        color:var(--gray4);
    
    }
    :local(.loading){
        padding:100px 0;
        text-align: center;
    }
    
}

@media(max-width:600px) {
    :local(.page){
        padding:0;
    }
}    
