
:local(.cnt){
  position: relative;
  text-align:center;
  padding:var(--spacing);
  display:grid;
  gap:var(--spacing);
  
}
:local(.battery){
  position:absolute;
  top:var(--spacing);
  left:var(--spacing);
  display:grid;
  gap:var(--spacing_quarter);
  align-items: center;
  grid-template-columns: repeat(3, auto);
  color: #09D76E;
}
:local(.battery.low){
  color:rgb(168, 14, 99);
}

:local(.lockStatus){
  position:absolute;
  top:var(--spacing);
  right:var(--spacing);
  svg{
    font-size: 30px;
  }
}
:local(.lockStatus .locked){
  color:#c30;
}
:local(.lockStatus .unlocked){
  color:#09D76E;
}
:local(.img img){
  max-width:200px;
  padding:40px;
  width:100%;
}
:local(.info){
  font-size: var(--small);
}

:local(.title){
  font-weight: bold;
  font-size: var(--big);
}
:local(.actions){
  display: grid;
  gap:var(--spacing);
  grid-template-columns: repeat(1, auto);
}

:local(.actions .lock){
  background:#c30;
  color:#fff;
}
:local(.actions .unlock){
  background:#09D76E;
  color:#222;
}
:local(.actions .updating){
  background:#ccc;
  color:#444;
}
:local(.actions .lock),
:local(.actions .unlock){
  border:0;
  border-radius:50px;
  font-size:24px;
  height:auto;
  padding:var(--spacing);
  svg{
    margin-right:var(--spacing);
  }
}

:local(.actions .unlock[disabled]),
:local(.actions .lock[disabled])
{
  background:#ddd;color:#666;
}

:local(.actionsAdmin){
  display:grid;
  gap:var(--spacing_quarter);
  align-items: center;
  grid-template-columns: repeat(3, auto); 
  button{
    color:#666;
  }
  svg{
    margin-right:var(--spacing_quarter);
  } 
}
@media (max-width: 600px){
  :local(.cnt){
    gap:var(--spacing);
  }
  :local(.actionsAdmin){
    grid-template-columns: repeat(1, auto); 
  }
}
