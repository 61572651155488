/* add css styles here (optional) */

:local(.ReactSwipeButton) {
    float: left;
    width: 100%;
    position: relative;
  }
  :local(.ReactSwipeButton),
  :local(.ReactSwipeButton) * {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none;   /* Chrome/Safari/Opera */
    -khtml-user-select: none;    /* Konqueror */
    -moz-user-select: none;      /* Firefox */
    -ms-user-select: none;       /* Internet Explorer/Edge */
    user-select: none;           /* Non-prefixed version, currently
                                    not supported by any browser */
  }
  :local(.rsbContainer){
    float: left;
    width: 100%;
    height: 100%;
    background: #eee;
    border-radius: 50px;
    position: relative;
    /* box-shadow: inset 1px 1px 4px rgba(0,0,0,0.1); */
    overflow: hidden;
  }
  :local(.rsbContainerUnlocked){
    width: 90%!important;
    margin-left: 5%;
    transition: 0.5s;
    cursor: default;
  }
  :local(.rsbContainerUnlocked .rsbcSlider) {
    left: 100%!important;
    cursor: default;
    pointer-events: none;
  }
  :local(.rsbContainerUnlocked .rsbcSliderArrow) {
    transition: 0.5s;
    margin-right: -60px;
  }
  :local(.rsbContainerUnlocked) :local(.rsbcSliderCircle) {
    transition: 0.5s;
    margin-right: -60px;
  }
  :local(.rsbcSlider) {
    float: left;
    width: 80px;
    position: absolute;
    height: 100%;
    top:0;
    left: 0px;
    border-radius: 25px;
    z-index: 100;
    /* box-shadow: 1px 1px 5px rgba(0,0,0,0.3); */
    cursor: pointer;
  }
  :local(.rsbcSliderText) {
    position: absolute;
    top:0;
    left:0;
    right:0;
    line-height: 100%;
    text-align: center;
    letter-spacing: 2px;
    color: #fff;
    font-size: 24px;
  
  }
  :local(.rsbcSliderArrow) {
    float: left;
    position: absolute;
    transform: rotate(45deg);
    border: 2px solid #fff;
    height: 8px;
    width: 8px;
    top:50%;
    right: 40px;
    margin-top: -6px;
    border-left-color: transparent;
    border-bottom-color: transparent;
    transform-origin: center;
    z-index: 10;
  }
  :local(.rsbcSliderCircle) {
    position: absolute;
    right: 0;
    background: #44444499;
    top:0;
    height: 80px;
    width: 80px;
    border-radius: 100%;
  }