:local(.cnt){
    position: relative;
    :local(.loading){
        top:0;
        left:0;
        right:0;
        bottom:0;
        background:#ffffffaa;
        position:absolute;
        display:grid;
        align-items:center;
        justify-items: center;
        z-index:10;
    }
}
:local(.message){
    text-align: center;
    padding: var(--spacing2x) var(--spacing_half);
    font-size: var(--small);
}
:local(.input){
    margin-top:var(--spacing);
    display: grid;
    grid-template-columns: 1fr auto;
    gap:var(--spacing_half);
    font-size: var(--big4x);
    input{
        font-size: var(--big2x);
    }
}
:local(.inputInner){
    position:relative;
}
:local(.scan){
    position:absolute;
    top:3px;
    right:7px;
}
:local(.logTable){
    margin-top:var(--spacing);
}
:local(.qrlinks){
    font-size: var(--normal);
    padding-top:var(--spacing_half);
}
:local(.qrlink){
    padding:var(--spacing_quarter);
}


@media (max-width: 600px){
    :local(.input){
        margin:var(--spacing_half);
        grid-template-columns: 1fr;
    }
        
    
}



:local(.modalScan){
    position:absolute;
    top:0;left:0;right:0;bottom:0;
    padding:10px;
    display: block;
    text-align: center;
    justify-content: center;
    align-items: center;
    background:#fff;
    z-index: 1000;
    :local(.closeBtn){
        background:#ccc;
        display: inline-block;
        margin-bottom:20px;
        width:30px;
        text-align: center;
        height:30px;
        line-height: 30px;
        cursor: pointer;
        font-size:20px;
        border-radius: 30px;
    }
    
}